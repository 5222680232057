import React, { useState } from 'react'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from 'gatsby-image'
import { graphql } from 'gatsby'

const EmployeeCard = ({data}) => {

    const [isActive, setIsActive] = useState(false)
    
    

    

    return (
        <div className={`employee-container ${isActive && 'active'}`} >
            <div className="employee-header">
                <div className="portrait">
                <Img fluid={data.frontmatter.image.childImageSharp.fluid} />
                </div>
                <div className="details">
                    <h3>{data.frontmatter.title}</h3>
                    {/* <h4>{data.frontmatter.position}</h4> */}

                </div>
                <button className="toggle-btn" onClick={() => setIsActive(!isActive)}>{isActive ? '-' : '+'}</button>
            </div>
            <div className="employee-content typography" dangerouslySetInnerHTML={{ __html: data.rawMarkdownBody }}>
                
            </div>
        </div>
    )
}



const AboutPage = ({data}) => {



    const WhoWeAreSection = ({data}) => (
        
        <section className="who-we-are">
            <div className="inner">
                    <h2>Who we are</h2>
                    {data.allMarkdownRemark.edges.map(e=>(
                        <EmployeeCard key={e.node.id} data={e.node}/>
                    ))}
                
            </div>   
        </section>

    )
    

    return ( 
        <Layout pageClass="about-page">
        <SEO title="About Us" />
        <main className="main">
            <div className="typography inner">
                <h1>About Experience On Tap</h1>

                <div className="paragraph-block">
                    <p>When Experience On Tap was founded in February 2005 I recognised that SMEs were full of hardworking, highly motivated people with great technical skills. What they often lacked however were management skills and experience. My vision was, and still is, for Experience On Tap to work with those business owners and managers to improve, enhance and support their endeavours by sharing our experience. </p>
                    <p>Since that time we have had the privilege to work in a diverse range of companies and industries throughout the greater Auckland area. Recognising that each person has different expertise, circumstances, abilities, and energy levels is crucial to the success of what we do for our clients. It is not a one size fits all approach. We devise a plan appropriate to you at this time.</p>

                </div>
            

            </div>
           
                

            <div className="our-purpose">
                <div className="inner typography">
                    <h2><span className="blue">Our purpose</span> is to release the potential of <span className="blue">small and medium businesses.</span></h2>
                </div>
                
            </div>

            <section className="our-values">   
                <div className="inner typography">
                    <h2>Our Core Values</h2>
                    <div className="values-container">
                        
                        <div className="value">
                            <h4>Honesty</h4>
                            <p>We will be realistic, honest and trustworthy in all our dealings with you.</p>
                        </div>
                        <div className="value">
                            <h4>Respect</h4>
                            <p>We will treat you with respect and will have the courage to tell you what you need to know.</p>
                        </div>
                        <div className="value">
                            <h4>Integrity</h4>
                            <p>At all times we will apply high moral principles in our dealings with you.</p>
                        </div>
                        <div className="value">
                            <h4>Flexibility</h4>
                            <p>Our approach will depend on your needs so our response is appropriate to meet your circumstances. We encourage adaptability and versatility.</p>
                        </div>
                        <div className="value">
                            <h4>Innovation</h4>
                            <p>We are motivated to look for opportunities to create and add value for our customers.</p>
                        </div>
                        <div className="value">
                            <h4>A Guiding Principle</h4>
                            <p>At its core business is all about people. We may have a great product or service but if it doesn’t meet the needs (or wants) of its target market it won't be a success. If we don’'t get the right people on the team and look after them we won't succeed long term. If our relationships with our suppliers are shaky we have problems. All of our stakeholders matter.</p>

                        </div>
                        

                       

                        

                       

                        

                        
                    </div>
                    
                </div>
                
            </section>
            <WhoWeAreSection data={data}/>
            </main>    
        </Layout>

    )
}


export const EmployeeQuery = graphql`
    query EmployeeDataQuery 
    {

        allMarkdownRemark {
          edges {
            node {
              id
              frontmatter{
                title
                position
                image {
                    childImageSharp {
                      fluid(maxWidth: 2000) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
              }
              rawMarkdownBody
            }
          }
        }
    }`

export default AboutPage


